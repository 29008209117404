<template>
  <section>
    <b-container fluid>
      <b-container>
        <h1>Promocje</h1>
        <div class="content">
          Wybierz sklep (z rozwijanej listy poniżej), żeby zobaczyć bieżące promocje:
          <b-form-select v-model="shop_id" @change="debouncePromotions" :options="options" size="sm"
                         class="mt-3 mb-3"></b-form-select>
        </div>

        <b-container>
          <b-row class="promotion-row" v-for="(item, index) in promotions" :key="index">
            <div  class="col-lg-3 col-md-12">
              <b-img :src="item.image" fluid></b-img>
            </div>
            <div class="col-md-9 col-lg-7">
              <strong>{{ item.translations[0].title }}</strong>
              <hr/>
              <div v-html="item.translations[0].content"></div>
            </div>
            <div class="points col-md-3 col-lg-2">+ {{ item.points }} pkt.</div>
          </b-row>
        </b-container>
        <hr/>
        <h3 v-if="!promotions.length && !$store.state.loading && shop_id">Brak promocji dla
          wybranego sklepu.</h3>
        <h3 v-else-if="!shop_id && !$store.state.loading">Proszę wybrać sklep.</h3>

      </b-container>
    </b-container>

  </section>
</template>
<script>
import { debounce } from 'lodash';
import ShopService from '../_services/shop.service';
import PageService from '../_services/pages.service';

const shopService = new ShopService();
const pageService = new PageService();

export default {
  name: 'Promotions',
  data() {
    return {
      promotions: [],
      options: [
        { value: '', text: 'Wybierz sklep' },
      ],
      shop_id: this.getShopId(),
    };
  },
  beforeCreate() {
    this.$store.state.loading = true;
  },
  async created() {
    this.getPromotionList();
    shopService.getShopList().then((shops) => {
      Object.values(shops).forEach((shop) => {
        this.options.push(shop);
      });
    });
  },
  methods: {
    debouncePromotions: debounce(function x() {
      this.getPromotionList();
    }, 250),

    getPromotionList() {
      this.$store.state.loading = true;
      localStorage.setItem('shop_promotion', this.shop_id);
      this.promotions = [];
      if (!this.shop_id) {
        this.$store.state.loading = false;
        return;
      }
      pageService.getPromotionsList(this.shop_id).then((result) => {
        this.promotions = [];
        Object.values(result.data).forEach((promotion) => {
          const item = promotion;
          item.image = process.env.VUE_APP_PROMOTION_IMAGES + promotion.photo;
          this.promotions.push(item);
        });
        this.$store.state.loading = false;
      });
    },
    getShopId() {
      if (localStorage.getItem('shop_promotion') === null || localStorage.getItem('shop_promotion') === 'null') {
        localStorage.shop_promotion = '';
      }
      return localStorage.getItem('shop_promotion');
    },
  },
};
</script>
<style>
  .promotion-row{
    border:1px solid black;
    min-height: 125px;
    margin-top:15px;
    padding:10px;
  }
  .points{
  display: flex;
    justify-content: center;
    align-self:center;
  }
</style>
