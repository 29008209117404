import http from '@/_helpers/http';

export default class shopService {
  /**
   * pobranie listy sklepów
   */
  getShopList(getInfo = 'forInput') {
    return http.axios.get(`${http.api.ACTION.SHOPS_LIST}`)
      .then((response) => {
        const temp = [];
        Object.values(response.data.data)
          .forEach((shop) => {
            if (getInfo === 'forInput') {
              temp.push({
                value: shop.id,
                text: `${shop.city} ${shop.street}`,
              });
            } else {
              temp.push(shop);
            }
          });
        return temp;
      });
  }
}
